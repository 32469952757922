import { useEffect, useState } from "react";
import Pagination from "../reuseables/pagination";
import { newsQuery, newSearchQuery } from "../../utils/data";
import { client } from "../../utils/client";

import pixelImage from '../../assets/images/bg-pattern-right(light-mode).svg';

interface NewInfo{
  _id: string;
  title: string;
  subtitle: string;
  duration: string;
  mainImageUrl: string;
  _createdAt: string;
  body: string;
}


const Customers = () => {
  const [newsList, setNewList] = useState<NewInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [searchParams, setSearchParams] = useState({ page: 1, pageSize: 10, searchTerm: ''});

  
  const fetchNewsBySearch = () =>{
    const query = newSearchQuery(searchParams);
    
    client.fetch(query)
    .then((data) => {
      setNewList(data);
      setLoading(false);
    })
  }

  const fetchNews = () =>{
    client.fetch(newsQuery(searchParams))
    .then((data) => {
      setNewList(data);
      setLoading(false);
    })
  }
  
  useEffect(() => {
    setLoading(true);
    
    if(searchParams.searchTerm !== ''){
      fetchNewsBySearch();
    } else {
      fetchNews();
    }

    const countQuery = `count(*[_type == "post" && status == 'active' && type == 'news'])`

    client.fetch(countQuery)
    .then((data : number) => {
      setTotalPage(Math.ceil(data / searchParams.pageSize));
    })

    // eslint-disable-next-line
  }, [searchParams]);

  const onPageChange = (page: number) => {
    setSearchParams({...searchParams, page: page});
    fetchNewsBySearch();
  }

  const onPageSizeChange = (pageSize: number) => {
    setSearchParams({...searchParams, pageSize: pageSize});
    fetchNewsBySearch();
  }
  
  return (
    <div className='flex flex-col items-center gap-10 w-full'>
      <div className='grid grid-cols-1 items-center lg:grid-cols-2 h-[25rem] lg:w-8/12 w-11/12 relative'>
        <div className="flex flex-col gap-5">
            <p className="text-gray-500">~/customers</p>
            <div className="text-4xl lg:text-[4rem] lg:leading-[3.7rem]">
              Meet Our <br/> customers.
            </div>
            <div className="flex flex-col gap-8">
              <p className="lg:text-lg">
                <span className="text-gray-500">
                  ECTC is trusted by thousands of developers 
                  <br className="hidden md:inline"/> 
                  and companies around the world.
                </span>
              </p>
              <div className="flex items-center gap-4 !gap-8">
                <a className="flex space-x-2 text-sm text-gray-500 hover:text-gray-700 lg:text-base"href="https://app.ECTC.co/register">
                  <span className="font-medium hover:underline ">Contact sales</span><span>→</span>
                </a>
              </div>
            </div>
        </div>

        <div className="lg:w-1/2 absolute right-0 hidden lg:block z-0 ">
            <img src={pixelImage} alt="pixel footer graphic"/>
        </div>
      </div>
      <div className='flex flex-col items-center bg-white lg:w-8/12 w-11/12 z-20'>
        <div className='grid lg:grid-cols-2 w-full lg:gap-[4rem] gap-5 lg:mt-10 mt-5'>
          {!loading && newsList?.map((post, index) => (
            <div className='rounded-[4px] text-left h-full border-2 bg-white hover:bg-gray-900 text-black hover:text-white border-gray-400 hover:border-black' key={post._id}>
              <img src={post?.mainImageUrl} alt={`news-${index}`} />
              <div className='text-xl w-full p-10 bg-white font-medium hover:underline text-gray-800'>
                {post?.title}
              </div>

              <div className='border-t-2 p-5 flex justify-between items-center gap-2 text-sm'>
                <div className='flex items-center gap-2 text-sm'>
                  Cal,com
                
                </div>
                <div className='flex items-center gap-2 text-sm'>
                  Read more
                  <span>→</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={`${newsList.length !== 0 ? 'hidden' : ''} w-full flex flex-col gap-3 items-center justify-center text-white my-[5rem]`}>
          <div className='text-2xl  font-semibold mb-3'>No results found</div>
          <div className=''>Uh oh! It seems like we couldn't find any articles matching your search criteria at the moment. </div>
          <div className=''>Don't worry, our team is constantly updating our blog with fresh content. </div>
          <div className=''>Why not try a different search term or explore our blog categories to discover something new? Happy exploring!</div>
          <div onClick={() => {setSearchParams({ ...searchParams, searchTerm: '' })}} className='text-white border py-3 px-10 lg:text-xl font-semibold cursor-pointer gap-2 mt-5 uppercase flex flex-row items-center'>
            <i className="fi fi-rr-angle-small-left mb-[-.3rem]"></i>
            Back to blog
          </div>
        </div>
      </div>


      <Pagination currentPage={searchParams.page} dataLength={newsList.length} pageSize={searchParams.pageSize} totalPages={totalPage} onPageSizeChange={onPageSizeChange} onPageChange={onPageChange} />
        
      <div className='flex flex-col items-center w-full py-10 border-t'>
        <div className='flex flex-col lg:items-center gap-3 lg:w-8/12 w-11/12 py-16'>
          <div className='lg:text-[2.5rem] text-center text-2xl'>
            Get the most of your data with ECTC
          </div>
          <p className='text-base md:text-lg mx-auto pt-2 text-center text-gray-500 font-inter lg:mx-0 lg:text-left'>Data-Driven Success Begins with ECTC Consulting</p>
        </div>
      </div>
    </div>
  )
}

export default Customers;
