// import {useEffect, useState}  from 'react';
import { Routes, Route } from 'react-router-dom';

import { AlertProvider } from '../utils/notification/alertcontext';
import Alert from '../utils/notification/alert';

// import Onboarding from '../components/onboarding/index'
import {About, Home, News, NewsDetail, NotFound, Services, Customers} from '../components/pages/_route';
import Navbar from '../components/reuseables/navbar';
import Footer from '../components/reuseables/footer';

const IndexRoutes = () => {
  // eslint-disable-next-line
//   const [loading, setLoading] = useState(true)

//   useEffect(() => {
//     setTimeout(() => {
//       setLoading(false)
//     }, 2000);
// }, [])

  // if (loading) {
  //   return <Onboarding/>
  // }
  
  return (
    <div className='bg-white flex flex-col items-center w-full relative'>
      <Navbar/>
      <main className='mt-[3rem] w-full'>
        <AlertProvider>
          <Alert />  
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/updates" element={<News/>} />
            <Route path="/updates/:id" element={<NewsDetail/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/customers" element={<Customers/>} />
            {/* <Route path="/professionals" element={<Professionals/>} />
            <Route path="/professionals/:id" element={<ProfessionalDetail/>} />
            <Route path="/clients" element={<Clients/>} />
            <Route path="/career" element={<Career/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/services/:id" element={<ServiceDetail/>} /> */}
            <Route path="/*" element={<NotFound/>} />
          </Routes>
        </AlertProvider>
      </main>
      <Footer />
    </div>
  )
}

export default IndexRoutes;