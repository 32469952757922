import { useState } from "react";
import Disclaimer from './disclaimer';

import logo from '../../assets/images/logo.png';
import pixelImage from '../../assets/images/pixels-footer.svg';


const Footer = () => {
    const date = new Date();
    const [handleDisclaimerPopUp, setHandleDisclaimerPopUp] = useState(false);

    const handleToggleModal = (newValue: boolean) => {
        setHandleDisclaimerPopUp(newValue);
    };

    if (handleDisclaimerPopUp) {
        return <Disclaimer handleToggleModal={handleToggleModal} />
    }

    return (
        <footer className="bg-black text-white w-full flex flex-col items-center py-[10rem]">
        <div className="lg:w-8/12 w-11/12 relative px-4 flex flex-col">
            <div className="grid grid-cols-1 items-end gap-8  text-sm lg:grid-cols-[1fr_3fr_346px] lg:gap-0">
                <img src={logo} alt='logo' className='h-10'/>
                <div className="space-y-4 lg:space-y-8">
                    <p className="text-gray-11">ECTC captures 100% of your event data for every possible <br/> need: o11y, security, analytics, and new insights.</p>
                </div>
                <div className="w-full space-y-4 lg:space-y-8">
                    <div className="flex  space-x-4">
                    <a
                        className="flex h-[40px] items-center justify-center rounded-[4px] px-4  text-sm  text-white bg-reinvent-blue hover:!bg-reinvent-blueHover shadow-inner shadow-sm w-[170px]"
                        href="https://app.ECTC.co/register">Sign up for free</a>
                    <a
                        className="flex h-[40px] items-center justify-center rounded-[4px] px-4  text-sm  border-[1.5px] text-gray-11 border-gray-08 hover:border-gray-09 hover:text-gray-12 w-[145px] space-x-2 whitespace-nowrap border"
                        href="/demo">
                        <span>Book a demo</span>
                        <span>→</span>
                    </a>
                    </div>
                </div>
            </div>
            <div className="mt-20 grid grid-cols-2 gap-[96px] text-sm lg:grid-cols-6">
                <ul className="space-y-3">
                    <li className=" text-gray-08">GET STARTED</li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12"
                            href="https://app.ECTC.co/register">Sign up</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/demo">Book a demo</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12"
                            href="https://app.ECTC.co/login">Login</a>
                    </li>
                </ul>
                <ul className="space-y-3">
                    <li className=" text-gray-08">SOLUTIONS</li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/features">Features</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12"
                            href="/solutions/observability">Observability</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/solutions/security">
                            Security</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/aws">AWS</a>
                    </li>
                </ul>
                <ul className="space-y-3">
                    <li className=" text-gray-08">RESOURCES</li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/play">Playground</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/docs">Documentation</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12"
                            href="/docs/restapi/introduction">API Reference</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/changelog">Changelog</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/docs/apl/introduction">
                            APL</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/docs/reference/cli">
                            CLI</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/support">Support</a>
                    </li>
                </ul>
                <ul className="space-y-3">
                    <li className=" text-gray-08">COMPANY</li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/pricing">Pricing</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/customers">Customers</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/blog">Blog</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/company#about">About</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/company#careers">
                            Careers</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/contact">Contact</a>
                    </li>
                </ul>
                <ul className="space-y-3">
                    <li className=" text-gray-08">LEGAL</li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/security">Security</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/privacy">Privacy
                            policy</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/terms">Terms of
                            service</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/sub-processors">
                            Sub-processors</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/cookies">Cookies</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/gdpr">GDPR</a>
                    </li>
                    <li className="">
                        <a className="text-gray-11 hover:text-gray-12" href="/sla">SLA</a>
                    </li>
                </ul>
                <ul className="hidden lg:block">
                    <li className=" text-gray-08">FOLLOW</li>
                    <ul
                        className="mt-3.5 flex flex-wrap items-start justify-center gap-3 text-sm lg:flex-col undefined">
                        <li className="text-gray-11 hover:text-gray-12">
                            <a className="flex items-center space-x-2" target="_blank"
                                aria-label="Link to Discord community" href="/discord">
                                <span className="inline-block ">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab"
                                        data-icon="twitter"
                                        className="svg-inline--fa fa-twitter fa-w-16" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                        width="20" height="20">
                                        <path fill="currentColor"
                                            d="M433.713 94.4983C400.074 79.0976 364.562 68.1718 328.083 62C323.092 70.9235 318.575 80.1045 314.552 89.5047C275.696 83.6495 236.181 83.6495 197.325 89.5047C193.3 80.1055 188.784 70.9245 183.794 62C147.292 68.2239 111.757 79.1756 78.0841 94.5788C11.2351 193.483 -6.88664 289.931 2.17422 385.01C41.3227 413.934 85.141 435.932 131.724 450.046C142.214 435.939 151.495 420.972 159.471 405.306C144.322 399.648 129.701 392.668 115.777 384.446C119.442 381.788 123.026 379.049 126.489 376.392C167.005 395.445 211.226 405.324 255.999 405.324C300.772 405.324 344.993 395.445 385.509 376.392C389.013 379.251 392.597 381.989 396.221 384.446C382.27 392.681 367.622 399.675 352.447 405.346C360.413 421.005 369.695 435.959 380.193 450.046C426.816 435.988 470.668 414.001 509.824 385.05C520.455 274.789 491.662 179.227 433.713 94.4983ZM170.948 326.537C145.698 326.537 124.838 303.623 124.838 275.434C124.838 247.244 144.973 224.129 170.867 224.129C196.761 224.129 217.46 247.244 217.017 275.434C216.574 303.623 196.681 326.537 170.948 326.537ZM341.05 326.537C315.761 326.537 294.981 303.623 294.981 275.434C294.981 247.244 315.116 224.129 341.05 224.129C366.985 224.129 387.523 247.244 387.08 275.434C386.637 303.623 366.783 326.537 341.05 326.537Z"></path>
                                    </svg>
                                </span>
                                <span className="inline">Discord</span>
                            </a>
                        </li>
                        <li className="text-gray-11 hover:text-gray-12">
                            <a href="https://twitter.com/ECTCFM"
                                className="flex items-center space-x-2" rel="noreferrer" target="_blank"
                                aria-label="Link to ECTC Twitter account">
                                <span className="inline-block ">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab"
                                        data-icon="twitter" role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 1200 1227" width="16" height="16.54">
                                        <path fill="currentColor"
                                            d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"></path>
                                    </svg>
                                </span>
                                <span className="inline">Twitter</span>
                            </a>
                        </li>
                        <li className="text-gray-11 hover:text-gray-12">
                            <a href="https://github.com/ECTChq"
                                className="flex items-center space-x-2" rel="noreferrer" target="_blank"
                                aria-label="Link to ECTC GitHub account">
                                <span className="inline-block ">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab"
                                        data-icon="twitter" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                        width="20" height="20">
                                        <path fill="currentColor"
                                            d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path>
                                    </svg>
                                </span>
                                <span className="inline">GitHub</span>
                            </a>
                        </li>
                        <li className="text-gray-11 hover:text-gray-12">
                            <a href="https://linkedin.com/company/ECTChq"
                                className="flex items-center space-x-2" rel="noreferrer" target="_blank"
                                aria-label="Link to ECTC LinkedIn">
                                <span className="inline-block ">
                                    <svg className="h-[21.5px] w-[21.5px]" width="24" height="24"
                                        fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549 1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"></path>
                                    </svg>
                                </span>
                                <span className="inline">LinkedIn</span>
                            </a>
                        </li>
                    </ul>
                </ul>
            </div>
            <div className="mt-16 space-y-8 text-sm text-gray-11">
                <p className="text-gray-08">Copyright ©  {date.getFullYear()} ECTC,  Inc. All rights reserved.</p>
            </div>
            <div className="absolute -bottom-24 hidden lg:block">
                <img src={pixelImage} alt="pixel footer graphic"/>
            </div>
        </div>
        </footer>
    )
}

export default Footer