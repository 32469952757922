import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BiSquareRounded } from "react-icons/bi";

import { client } from "../../utils/client";
import { newsDetailMoreQuery } from "../../utils/data";



import { useAlert } from "../../utils/notification/alertcontext";
import { EMAIL_REGEX } from "../../utils/regex";
import AutoScrollCarousel from "../reuseables/autoScrollCarousel";

import barclayLogo from '../../assets/images/barclays_logo_bw.jpeg';
import lloydLogo from '../../assets/images/lloydsBankLogo_bw.png';
import airfranceLogo from '../../assets/images/air france_bw.png';
import dshcLogo from '../../assets/images/dhsc logo_bw.png';
import dftLogo from '../../assets/images/dft logo_bw.png';
import discoveryLogo from '../../assets/images/discovery_bw.png';
import rollsroyceLogo from '../../assets/images/rolls royce_bw.png';
import gskLogo from '../../assets/images/gsk_bw.png';


import ingestImageLogo from '../../assets/images/ingest-image.png';
import queryImageLogo from '../../assets/images/query-image.png';
import routeImageLogo from '../../assets/images/route-image.png';
import dashboardImage from '../../assets/images/Picture2.png';


import { getFormattedDate } from "../../utils/common";

const imageList = [
  barclayLogo,
  lloydLogo,
  airfranceLogo,
  dshcLogo,
  dftLogo,
  discoveryLogo,
  rollsroyceLogo,
  gskLogo,
  barclayLogo,
  lloydLogo,
  airfranceLogo,
  dshcLogo,
  dftLogo,
  discoveryLogo,
  rollsroyceLogo,
  gskLogo,
];


interface NewInfo {
  _id: string;
  title: string;
  subtitle: string;
  duration: string;
  mainImageUrl?: string;
  body: Object;
  _createdAt: string;
}

interface ContactDocument {
  _type: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}

const Home = () => {
  const { addAlert } = useAlert();
  const [newsList, setNewsList] = useState<NewInfo[]>([]);
  
  useEffect(() => {
    const getNewsQuery = newsDetailMoreQuery('');

    client.fetch(getNewsQuery)
      .then((data) => {
        setNewsList(data);
      })
  }, [])


  return (
    <div className='flex flex-col items-center lg:gap-32 gap-10 w-full'>
      <div className='flex flex-col bg-black w-full'>
        <div className='flex flex-col w-full justify-center items-center lg:h-[50rem] h-[25rem] text-white bg-pattern'>
          <div className='flex flex-col gap-7 lg:w-8/12 w-11/12'>
            <div className='text-gray-12 lg:text-[22px] lg:leading-[32px]'>Data <span className='text-[#00ab44] font-extrabold px-2'>|</span> re-imagined</div>
            <div className='text-[28px] text-gray-12 md:text-4xl lg:text-[56px] lg:leading-[64px]'>Your Data, <br/> Our Mastery.</div>
            <div className='text-sm text-gray-12 lg:text-[22px] lg:leading-[32px]'>100% of your data utilised: every byte counts </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-10 lg:w-8/12 w-11/12 items-center'>
        <div className='text-gray-500 tracking-normal text-center text-sm text-gray-11 group-hover:text-gray-12 lg:text-base'>ECTC is trusted by businesses of all sizes: <br className='inline md:hidden'/> Small to Enterprise.</div>
        <AutoScrollCarousel images={imageList} />
      </div>
      <div className='grid lg:grid-cols-2 gap-10 lg:w-8/12 w-11/12 items-center'>
        <div className='flex flex-col w-full gap-5'>
          <h2 className='text-center text-3xl text-gray-12 lg:text-left lg:text-[40px] lg:leading-[56px]'>Specialising in the <br/>  Microsoft Data Landscape</h2>
          <p className='text-base md:text-lg mx-auto max-w-[480px] pt-2 text-center text-gray-500 lg:mx-0 lg:text-left font-inter'>We excel in navigating the intricate world of the Microsoft Data Landscape. We provide tailored solutions leveraging Microsoft's robust suite of data tools, from Azure Data Services to Power BI, Fabric, Power Platform and beyond. With a deep understanding of Microsoft's ecosystem, we help clients harness the power of their data through:</p>
          <div className='flex flex-col mx-auto max-w-[480px] space-y-2 text-gray-12 lg:mx-0 lg:max-w-none'>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-lg"/>
              <div>Microsoft Fabric </div>
            </div>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-lg"/>
              <div>Microsoft Power BI</div>
            </div>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-lg"/>
              <div>Microsoft Azure</div>
            </div>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-lg"/>
              <div>Microsoft Power Platform</div>
            </div>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-lg"/>
              <div>Microsoft AI and Machine Learning</div>
            </div>

          </div>
        </div>
        <div className="flex justify-center">
          <img alt="[object Object] Illustration" loading="lazy" width="596" height="598" decoding="async" data-nimg="1" src={ingestImageLogo}/>
        </div>
      </div>
      <div className='grid lg:grid-cols-2 gap-10 lg:w-8/12 w-11/12 items-center'>
        <div className='flex flex-col w-full gap-5 lg:order-2'>
          <h2 className='text-center text-3xl text-gray-12 lg:text-left lg:text-[40px] lg:leading-[56px]'>Accurate Prediction <br/> and Forecasting.</h2>
          <p className='text-base md:text-lg mx-auto max-w-[480px] pt-2 text-center text-gray-500 lg:mx-0 lg:text-left font-inter'>ECTC leverages the expansive Microsoft data ecosystem to provide cutting-edge AI and machine learning solutions, focusing on prediction and forecasting.</p>
          <div className='flex flex-col mx-auto max-w-[480px] space-y-2 text-gray-12 lg:mx-0 lg:max-w-none'>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-lg"/>
              <div>Enhanced Accuracy with ML models</div>
            </div>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-lg"/>
              <div>Real-Time Analytics using Azure’s capabilities</div>
            </div>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-lg"/>
              <div>Dynamic scalability to meet the client’s needs</div>
            </div>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-lg"/>
              <div>Customization for forecasting models</div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <img alt="[object Object] Illustration" loading="lazy" width="596" height="598" decoding="async" data-nimg="1" src={queryImageLogo}/>
        </div>
      </div>
      <div className='grid lg:grid-cols-2 gap-10 lg:w-8/12 w-11/12 items-center'>
        <div className='flex flex-col w-full'>
          <p className='text-[#00ab44] text-sm lg:text-left text-center'>[Preview]</p>
          <h2 className='pb-4 text-center text-3xl text-gray-12 lg:text-left lg:text-[40px] lg:leading-[56px]'>Data optimised</h2>
          <p className='text-base md:text-lg mx-auto max-w-[480px] pt-2 text-center text-gray-500 font-inter lg:mx-0 lg:text-left'>ECTC specialises in providing comprehensive data services that empower businesses to leverage their data effectively. Here's a summary of our offerings:</p>
          <div className='flex flex-col gap-2 mx-auto max-w-[480px] space-y-2 pt-8 text-gray-12 lg:mx-0 lg:max-w-none'>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-2xl"/>
              <div>Data Analytics: Predictive Analytics, Business Intelligence, Real-Time Analytics</div>
            </div>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-2xl"/>
              <div>Cloud Data Services: Cloud Data Migration,  Cloud Data Architecture, Cloud Security for Data</div>
            </div>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-2xl"/>
              <div>Data Visualization: Custom Dashboard Creation, Reporting Tools, Interactive Data Exploration</div>
            </div>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] mt-[-.3rem] text-2xl"/>
              <div>Data Strategy & Consulting: Data Strategy Formulation, Data Architecture Planning, Digital Transformation</div>
            </div>
          </div>
        </div>
        <img src={routeImageLogo} className='' alt="background"/>
      </div>
      <div className='bg-[#F9F9F9] border-y w-full flex flex-col items-center'>
        <div className='bg-pattern w-full flex flex-col items-center'>
          <div className='flex flex-col items-center lg:w-8/12 w-11/12 my-24'>
            <h2 className='pb-4 text-center text-3xl text-gray-12 lg:text-[40px] lg:leading-[48px]'>Powerful dashboards</h2>
            <p className='text-base md:text-lg max-w-prose text-gray-500 text-center font-inter pt-5'>ECTC creates bespoke Power BI dashboards that offer dynamic data visualization, tailored to meet specific business insights needs. These dashboards integrate seamlessly with the Microsoft ecosystem for real-time analytics, are scalable, user-accessible, and secure. We also provide training and support to ensure effective use and continuous improvement of the dashboards.</p>
            <img src={dashboardImage} className='bg-white w-4/5 border mt-[7rem] mb-[-10rem] shadow-sm' alt="background"/>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col gap-5 items-center'>
        <div className='flex flex-col items-center gap-4 lg:w-8/12 w-11/12 my-10 font-inter'>
          <p className='text-base md:text-lg mx-auto mb-4 max-w-prose text-gray-600 text-center'>“ECTC's AI-driven approach was a game-changer for us. Their team not only analyzed our data but also implemented machine learning models that gave us predictive insights, allowing proactive business decisions.“</p>
          <img src={barclayLogo} className='mt-10' alt="background"/>
          <div className='text-base text-gray-500 hover:text-gray-600'>Petra Donka | Senior Engineering Manager</div>
        </div>
        <div className='lg:text-[2.3rem] text-2xl tracking-relaxed leading-[2.5rem] mt-10 text-center heading-2'>Everything you need, and more</div>
        <div className='grid lg:grid-cols-3 gap-10 lg:w-8/12 w-11/12 lg:mt-5 items-center'>
          <div className='flex flex-col space-y-3 rounded-[4px] border border-gray-200 p-6 text-left bg-[#fcfcfc] h-full'>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h4 className="text-lg">Data Analytics</h4>
            </div>
            <p className="text-sm md:text-base text-gray-500 font-inter">Turning data into insights with predictive, descriptive, and prescriptive method</p>
          </div>
          <div className='flex flex-col space-y-3 rounded-[4px] border border-gray-200 p-6 text-left bg-[#fcfcfc] h-full'>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h4 className="text-lg">Data Governance</h4>
            </div>
            <p className="text-sm md:text-base text-gray-500 font-inter">Ensuring data reliability, compliance, and effective stewardship</p>
          </div>
          <div className='flex flex-col space-y-3 rounded-[4px] border border-gray-200 p-6 text-left bg-[#fcfcfc] h-full'>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h4 className="text-lg">Data Warehouse</h4>
            </div>
            <p className="text-sm md:text-base text-gray-500 font-inter">Centralizing and optimizing data storage for efficient querying and analysis.</p>
          </div>
          <div className='flex flex-col space-y-3 rounded-[4px] border border-gray-200 p-6 text-left bg-[#fcfcfc] h-full'>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h4 className="text-lg">AI & ML</h4>
            </div>
            <p className="text-sm md:text-base text-gray-500 font-inter">Enhancing decision-making with machine learning algorithms and AI integration.</p>
          </div>
          <div className='flex flex-col space-y-3 rounded-[4px] border border-gray-200 p-6 text-left bg-[#fcfcfc] h-full'>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h4 className="text-lg">Data Migration</h4>
            </div>
            <p className="text-sm md:text-base text-gray-500 font-inter">Seamless transfer of data across systems for modernization or consolidation.</p>
          </div>
          <div className='flex flex-col space-y-3 rounded-[4px] border border-gray-200 p-6 text-left bg-[#fcfcfc] h-full'>
            <div className='flex flex-row gap-2 items-center'>
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h4 className="text-lg">Data Security</h4>
            </div>
            <p className="text-sm md:text-base text-gray-500 font-inter">Protecting data with encryption, compliance, and threat management.</p>
          </div>
        </div>
      </div>
      <div className='flex flex-col lg:items-center lg:w-8/12 w-11/12 py-10'>
        <div className='lg:text-[2.5rem] text-center text-2xl'>
          Latest news & updates
        </div>
        <div className='grid lg:grid-cols-3 w-full lg:gap-[4rem] gap-5 mt-10'>
          {newsList?.map((post, index) => (
            <NavLink to={`/updates/${post?._id}`} className='rounded-[4px] text-left h-full' key={post._id}>
              <div className="rounded-[4px] border">
                <img src={post?.mainImageUrl} alt={`news-${index}`} />
              </div>
              <div className='mb-2 mt-4 text-sm text-gray-500'>
                {getFormattedDate(post?._createdAt)}
              </div>
              <div className='text-xl font-medium hover:underline text-gray-800 group-hover:underline'>
                {post?.title}
              </div>

              <div className=' mt-4 flex items-center gap-2  text-gray-500 text-sm'>
                Read more
                <i className="fi fi-rr-arrow-small-right mb-[-.3rem]"></i>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
      <div className='flex flex-col items-center w-full py-10 border-t'>
        <div className='flex flex-col lg:items-center gap-3 lg:w-8/12 w-11/12 py-16'>
          <div className='lg:text-[2.5rem] text-center text-2xl'>
            Get the most of your data with ECTC
          </div>
          <p className='text-base md:text-lg mx-auto pt-2 text-center text-gray-500 font-inter lg:mx-0 lg:text-left'>Data-Driven Success Begins with ECTC Consulting</p>
        </div>
      </div>
    </div>
  )
}

export default Home;
