import collectionImage from '../../assets/images/fabric_consulting.png';
import comprehensiveImage from '../../assets/images/bi_consulting.png';
import dashboardImage from '../../assets/images/platform_consulting.png';
import filterImage from '../../assets/images/machine_learning.png';

import plexLogo from '../../assets/images/plex-grayscale.svg';
import prismaLogo from '../../assets/images/prisma-grayscale.svg';
import ruggableLogo from '../../assets/images/ruggable-grayscale.svg';
import { BiSquareRounded } from 'react-icons/bi';


const Services = () => {  
  return (
    <div className='flex flex-col items-center lg:gap-[5rem] gap-10 w-full'>
      <div className='flex flex-col w-full items-center border-b'>
        <div className='flex flex-col lg:w-8/12 w-11/12'>
          <div className='flex flex-col w-full justify-center items-center text-gray gap-7 bg-pattern'>
            <div className="relative flex justify-center py-8 md:py-[9rem] lg:py-[11rem]">
              <div className="bg-white md:px-8 lg:w-4/5 text-center">
                <div className="relative z-40 flex flex-col space-y-3">
                  <div className="flex flex-col space-y-4 ">
                    <h2 className="flex items-center justify-center pb-4 text-sm text-gray-11">~/services</h2>
                    <h1 className="heading-1 !mt-2 text-3xl leading-tight text-gray-12 md:!mb-2 md:text-[48px] lg:whitespace-pre-wrap">
                      Unlock the data-verse for your entire organization.
                    </h1>
                  </div>
                  <div className="flex flex-col items-center gap-8">
                    <p className="lg:text-lg md:w-3/5">
                      <span className="font-inter text-gray-500 md:whitespace-pre-wrap">Gain an unparalleled
                        ability to observe your systems and applications, with ECTC’s unified event platform.
                      </span>
                    </p>
                    <div className="flex items-center gap-4 !mt-0 justify-center ">
                      <a className="flex h-[40px] items-center justify-center rounded-[4px] px-4 text-sm  text-white bg-[#00ab44] hover:!bg-[#00ab44] hover:shadow-inner shadow-sm space-x-2" href="/services#components">
                        <span>Learn more</span><span>↓</span>
                      </a>
                      <a className="flex h-[40px] items-center justify-center rounded-[4px] px-4 text-sm  border-[1.5px] text-gray-11 border-gray-08 hover:border-gray-09 hover:text-gray-12 space-x-2 bg-gray-01" href="/contact">
                        <span>Contact sales</span><span>→</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col lg:items-center gap-3 lg:w-8/12 w-11/12 py-16'>
        <div className="space-y-16 md:space-y-32 lg:space-y-32" id="components">
          <section id="flexible-data-collection"
            className="grid scroll-mt-32 grid-cols-1 items-center gap-16 md:grid-cols-2 md:gap-16">
            <div className="order-1 md:order-1">
              <div className="block border-[12px]">
                <span><img src={collectionImage} className='' alt="background"/></span>
              </div>
            </div>
            <div className="order-0 md:order-0">
              <h2 className="text-2xl text-gray-12 lg:text-[35px] lg:leading-[42px]">Microsoft Fabric Consulting</h2>
              <p className="whitespace-pre-wrap pt-4 leading-loose text-gray-600 font-inter">
                Microsoft Fabric represents a significant evolution in the
                realm of data analytics and business intelligence. ECTC
                stands at the forefront of Microsoft Fabric consulting,
                leveraging deep expertise in Microsoft's cloud and analytics
                solutions.
              </p>
            </div>
          </section>
          <section id="comprehensive-query-language"
            className="grid scroll-mt-32 grid-cols-1 items-center gap-16 md:grid-cols-2 md:gap-16">
            <div className="order-1 md:order-0">
              <div className="block border-[12px]">
                <span><img src={comprehensiveImage} className='' alt="background"/></span>
              </div>
            </div>
            <div className="order-0 md:order-1">
              <h2 className="text-2xl text-gray-12 lg:text-[35px] lg:leading-[42px]">Microsoft Power BI Consulting</h2>
              <p className="whitespace-pre-wrap pt-4 leading-loose text-gray-600 font-inter">
                Our consultants excel in creating bespoke Power BI
                implementations that align with your business objectives,
                ensuring seamless integration with existing systems. We
                focus on intuitive dashboard design, advanced data
                modeling, and providing actionable insights through Power
                BI. ECTC's approach includes strategic planning, custom
                development, and comprehensive training, enhancing your
                team's data literacy.
              </p>
            </div>
          </section>
          <section id="dashboards,-monitors,-and-apps"
            className="grid scroll-mt-32 grid-cols-1 items-center gap-16 md:grid-cols-2 md:gap-16">
            <div className="order-1 md:order-1">
              <div className="block border-[12px]">
                <span><img src={dashboardImage} className='' alt="background"/></span>
              </div>
            </div>
            <div className="order-0 md:order-0">
              <h2 className="text-2xl text-gray-12 lg:text-[35px] lg:leading-[42px]">Microsoft Power Platform Consulting</h2>
              <p className="whitespace-pre-wrap pt-4 leading-loose text-gray-600 font-inter">
                ECTC's consultants are certified experts, adept at driving digital
                transformation through automation, insightful data analysis, and
                custom app development. We offer strategic planning,
                implementation, and training, empowering your team to utilize
                the Power Platform effectively.
              </p>
            </div>
          </section>
          <section id="filter,-transform,-and-route"
            className="grid scroll-mt-32 grid-cols-1 items-center gap-16 md:grid-cols-2 md:gap-16">
            <div className="order-1 md:order-0">
              <div className="block border-[12px]">
                <span><img src={filterImage} className='' alt="background"/></span>
              </div>
            </div>
            <div className="order-0 md:order-1">
              <h6 className="pb-4 text-lg text-[#00ab44]">[Preview]</h6>
              <h2 className="text-2xl text-gray-12 lg:text-[35px] lg:leading-[42px]">Microsoft AI and Machine Learning Consulting</h2>
              <p className="whitespace-pre-wrap pt-4 leading-loose text-gray-600 font-inter">
                We offer strategic guidance, from AI feasibility studies to full
                lifecycle management of AI projects, ensuring your initiatives
                align with business objectives. ECTC's approach includes
                custom algorithm development, ethical AI implementation,
                and continuous model optimization
              </p>
            </div>
          </section>
        </div>
      </div>
      <div className="flex flex-col lg:items-center gap-3 lg:w-8/12 w-11/12 lg:py-16">
        <div className="text-center items-center flex flex-col">
          <h2 className="text-2xl text-gray-12 lg:text-[35px] lg:leading-[42px]">Why use ECTC</h2>
          <p className="whitespace-pre-wrap pt-4 text-gray-500 lg:text-lg font-inter md:w-3/5">
            ECTC stands out in the UK consulting scene by exclusively employing UK-based consultants, ensuring local insights and cultural alignment.
          </p>
        </div>
        <section className="grid grid-cols-1 items-stretch gap-16 pt-8 md:grid-cols-2">
          <div className="scroll-mt-80" id="long-term-active-archive">
            <div className="flex flex-col space-y-3 rounded-[4px] border border-gray-06 bg-gray-50 p-6 text-left h-full whitespace-pre-wrap leading-loose text-black">
              <div className="flex items-center space-x-2">
                <BiSquareRounded className="text-[#00ab44] text-lg"/>
                <h4 className="text-lg text-gray-800">Local Expertise and Insight</h4>
              </div>
              <p className="text-sm md:text-base text-gray-500 font-inter">
                Our consultants are deeply embedded in the UK market, offering insights that are
                not just informed by global trends but are specifically tailored to the nuances of the
                British business landscape. This local knowledge ensures that our solutions are
                relevant, compliant with UK regulations, and aligned with cultural and business
                practice
              </p>
            </div>
          </div>
          <div className="scroll-mt-80" id="analytics-datastore">
            <div className="flex flex-col space-y-3 rounded-[4px] border border-gray-06 bg-gray-50 p-6 text-left h-full whitespace-pre-wrap leading-loose text-black">
              <div className="flex items-center space-x-2">
                <BiSquareRounded className="text-[#00ab44] text-lg"/>
                <h4 className="text-lg text-gray-800">Data Security</h4>
              </div>
              <p className="text-sm md:text-base text-gray-500 font-inter">
                In an era where data breaches can have significant consequences, ECTC's UK-based operations mean that sensitive information remains within the country, adhering to stringent UK data protection laws like GDPR. Our clients can rest
                assured that their data is secure and handled with the utmost care.
              </p>
            </div>
          </div>
        </section>
      </div>
      
      <div className="flex flex-col lg:items-center gap-3 lg:w-8/12 w-11/12 py-16">
        <div className="text-center">
          <h2 className="text-2xl text-gray-12 lg:text-[35px] lg:leading-[42px]">Customer stories</h2>
          <p className="whitespace-pre-wrap pt-4 text-gray-500 lg:text-lg font-inter">
            Learn from other customers who have shared your same difficulties.
          </p>
        </div>
          <div>
            <section className="grid grid-cols-1 gap-8 pt-16 lg:grid-cols-3">
              <div
                className="flex flex-col space-y-3 rounded-[4px] border border-gray-06 bg-gray-50 p-6 text-left undefined">
                <div className="flex items-center space-x-2">
                  <div className="text-[#00ab44]"><img className="w-20" src={plexLogo} alt="logo"/></div>
                  <h4 className="text-lg text-gray-12"> </h4>
                </div>
                <p className="text-sm md:text-base text-gray-600 font-inter"><span className="block pt-2">“Monitoring our logs from our
                    Next.js app deployed to Vercel was easy thanks to ECTC‘s Vercel integration and pre-built
                    dashboard.”</span></p>
              </div>
              <div
                className="flex flex-col space-y-3 rounded-[4px] border border-gray-06 bg-gray-50 p-6 text-left undefined">
                <div className="flex items-center space-x-2">
                  <div className="text-[#00ab44]"><img className="w-32" src={prismaLogo} alt="logo"/></div>
                  <h4 className="text-lg text-gray-12"> </h4>
                </div>
                <p className="text-sm md:text-base text-gray-600 font-inter"><span className="block pt-2">“ECTC is the modern serverless
                    observability tool I always wanted. The strong focus on developer experience is a great match for
                    how we do things at Prisma!”</span></p>
              </div>
              <div
                className="flex flex-col space-y-3 rounded-[4px] border border-gray-06 bg-gray-50 p-6 text-left undefined">
                <div className="flex items-center space-x-2">
                  <div className="text-[#00ab44]"><img className="w-36" src={ruggableLogo} alt="logo"/></div>
                  <h4 className="text-lg text-gray-12"> </h4>
                </div>
                <p className="text-sm md:text-base text-gray-600 font-inter"><span className="block pt-2">“I can hire an engineer off the
                    street, and they immediately know how to get value out of ECTC. That’s way different from other
                    monitoring.”</span></p>
              </div>
            </section>
            <div className="flex justify-center pt-16"><a
                className="flex h-[40px] items-center justify-center rounded-[4px] px-4 text-sm  border-[1.5px] text-gray-11 border-gray-08 hover:border-gray-09 hover:text-gray-12 space-x-2"
                href="/customers"><span>See all customers</span></a></div>
          </div>
      </div>      
      <div className='flex flex-col items-center w-full py-10 border-t'>
        <div className='flex flex-col lg:items-center gap-3 lg:w-8/12 w-11/12 py-16'>
          <div className='lg:text-[2.5rem] text-center text-2xl'>
            Get the most of your data with ECTC
          </div>
          <p className='text-base md:text-lg mx-auto pt-2 text-center text-gray-500 font-inter lg:mx-0 lg:text-left'>Data-Driven Success Begins with ECTC Consulting</p>
        </div>
      </div>
    </div>
  )
}

export default Services;
