import { useEffect, useState } from "react";
import { BiSquareRounded } from "react-icons/bi";
import { useLocation } from "react-router-dom";


const About = () => {
    const [selectedLabel, setSelectedLabel] = useState('All positions')
    const location = useLocation();

    useEffect(() => {
      if (location.hash === '#careers') {
        const careersSection = document.getElementById('careers');
        if (careersSection) {
          careersSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  return (
    <div className='flex flex-col items-center w-full'>
      <div className='flex flex-col bg-black w-full items-center border-b'>
        <div className='flex flex-col lg:w-8/12 w-11/12'>
          <div className='flex flex-col w-full justify-center items-center text-white gap-7 bg-pattern'>
            <div className="relative flex justify-center py-8 md:py-[9rem] lg:py-[11rem]">
              <div className="md:px-8 lg:w-4/5 text-center">
                <div className="relative z-40 flex flex-col space-y-3">
                  <div className="flex flex-col space-y-4 ">
                    <h2 className="flex items-center justify-center pb-4 text-sm">~/about us</h2>
                    <h1 className="heading-1 !mt-2 text-3xl leading-tight md:!mb-2 md:text-[68px] lg:whitespace-pre-wrap">
                      ECTC’s Mission
                    </h1>
                  </div>
                  <div className="flex flex-col items-center gap-8">
                    <p className="lg:text-lg md:w-4/5">
                      <span className="md:whitespace-pre-wrap">
                        All your event data. All the time. All in one place. For every possible need or desire.
                      </span>
                    </p>
                    <div className="flex items-center gap-4 !mt-0 justify-center ">
                      <a className="flex h-[40px] items-center justify-center rounded-[4px] px-4 text-sm  text-white bg-[#00ab44] hover:!bg-[#00ab44] hover:shadow-inner shadow-sm space-x-2" href="/about#careers">
                        <span>View open careers</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full bg-black text-white">
        <div className="flex flex-col lg:w-8/12 w-11/12">
          <div className="mt-16 pb-16 md:mt-32">
            <div className="-mt-36 pb-48 pt-16">
              <div className="grid grid-cols-1 gap-16 rounded-[4px]  p-12  shadow-xl md:grid-cols-3 md:p-16">
                <h2 className="mb-3 text-2xl text-gray-12 md:text-3xl lg:text-4xl text-12 font-mono lg:col-span-3">We take
                  an innovative approach<br/> to company values.</h2>
                <div className="flex flex-col space-y-3">
                  <div className="flex items-center space-x-2">
                    <BiSquareRounded className="text-lg"/>
                    <h3 className="font-mono font-medium text-gray-12">Customer conscious</h3>
                  </div>
                  <p className="text-base leading-loose font-inter">We value our customers by listening to their feedback
                    and being an advocate for their needs.</p>
                </div>
                <div className="flex flex-col space-y-3">
                  <div className="flex items-center space-x-2">
                    <BiSquareRounded className="text-lg"/>
                    <h3 className="font-mono font-medium text-gray-12">Be bold</h3>
                  </div>
                  <p className="text-base leading-loose font-inter">Be courageous and have no fear when experimenting with
                    new ideas.</p>
                </div>
                <div className="flex flex-col space-y-3">
                  <div className="flex items-center space-x-2">
                    <BiSquareRounded className="text-lg"/>
                    <h3 className="font-mono font-medium text-gray-12">Adapt and evolve</h3>
                  </div>
                  <p className="text-base leading-loose font-inter">Maintaining flexibility is key to moving forward in
                    this ever-changing world.</p>
                </div>
                <div className="flex flex-col space-y-3">
                  <div className="flex items-center space-x-2">
                    <BiSquareRounded className="text-lg"/>
                    <h3 className="font-mono font-medium text-gray-12">Own it</h3>
                  </div>
                  <p className="text-base leading-loose font-inter">We believe in giving our employees the autonomy to
                    truly own what they build.</p>
                </div>
                <div className="flex flex-col space-y-3">
                  <div className="flex items-center space-x-2">
                    <BiSquareRounded className="text-lg"/>
                    <h3 className="font-mono font-medium text-gray-12">Championing people</h3>
                  </div>
                  <p className="text-base leading-loose font-inter">We are built on personal and professional integrity
                    where every individual has the opportunity to make a difference.</p>
                </div>
                <div className="flex flex-col space-y-3">
                  <div className="flex items-center space-x-2">
                    <BiSquareRounded className="text-lg"/>
                    <h3 className="font-mono font-medium text-gray-12">Trust and transparency</h3>
                  </div>
                  <p className="text-base leading-loose font-inter">We foster a collaborative environment and encourage
                    people to give and ask for feedback early and often.</p>
                </div>
              </div>
            </div>
            <div className="-mt-32 pb-16 text-center md:-mt-16">
              <h5 className="text-base font-mono text-gray-12">We are supported by some of the smartest people on the
                planet.</h5>
              <div className="mx-auto mt-10 grid max-w-7xl grid-cols-6 items-center gap-6 md:gap-0 md:py-0">
                <span className="col-span-2 flex scale-75 items-center justify-center md:col-span-1 md:scale-100"><img src="https://ECTC.co/investors/crane-logo.svg" className='rounded-full' alt="background"/></span>
                <div className="col-span-2 flex scale-75 items-center justify-center md:col-span-1 md:scale-100">
                  <div className="flex flex-col items-center justify-center text-sm md:flex-row md:space-x-4">
                    <span><img src="https://Axiom.co/_next/image?url=%2Finvestors%2Fnat.jpg&w=48&q=75" className='rounded-full' alt="background"/></span>
                    <div className="flex flex-col items-center md:items-start">
                      <h5 className="text-gray-12">Nat Friedman</h5>
                      <h6 className="text-[12px] font-inter">Former CEO of Github</h6>
                    </div>
                  </div>
                </div>
                <span className="col-span-2 flex scale-75 items-center justify-center md:col-span-1 md:scale-100"><img src="https://Axiom.co/investors/crane-logo.svg" className='rounded-full' alt="background"/></span>
                <span className="col-span-2 flex scale-75 items-center justify-center md:col-span-1 md:scale-100"><img src="https://Axiom.co/investors/crane-logo.svg" className='rounded-full' alt="background"/></span>
                <div className="col-span-2 flex scale-75 items-center justify-center md:col-span-1 md:scale-100">
                  <div className="flex flex-col items-center justify-center text-sm md:flex-row md:space-x-4">
                    <span><img src="https://Axiom.co/_next/image?url=%2Finvestors%2Fadam.jpg&w=48&q=75" className='rounded-full' alt="background"/></span>
                    <div className="flex flex-col items-center md:items-start">
                      <h5 className="text-gray-12">Adam Wiggins</h5>
                      <h6 className="text-[12px] font-inter">Founder of Heroku</h6>
                    </div>
                  </div>
                </div>
                <span className="col-span-2 flex scale-75 items-center justify-center md:col-span-1 md:scale-100"><img src="https://ECTC.co/investors/crane-logo.svg" className='rounded-full' alt="background"/></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="careers" className="flex flex-col lg:w-8/12 w-11/12 scroll-mt-[72px] py-10 lg:py-[6rem]">
        <div className=" grid grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col text-center lg:items-start lg:text-left">
            <h2 className="mb-3 text-2xl text-gray-12 md:text-3xl lg:text-3xl font-mono !leading-normal !text-gray-12">
              <span className="pr-1 text-[#00ab44]">Join us.</span> Help developers <br/>
              around the world with data.</h2>
            <p className="text-sm md:text-base mx-auto max-w-[480px] text-gray-600 font-inter lg:mx-0">A fully remote team that works
              across 11 timezones. We help give developers the power to gain instant, actionable insights on all their
              data as efficiently as possible. Join us in changing how developers think about data.</p>
            <div className="flex justify-center lg:justify-start"><a
                className="flex h-[40px] items-center justify-center rounded-[4px] px-4 font-mono text-sm  text-white bg-[#00ab44] hover:!bg-[#00ab44] hover:shadow-inner shadow-sm mt-6"
                href="/about#jobs">See open positions</a></div>
          </div>
          <div className="relative mt-8 lg:mt-0">
            <div className="flex flex-col">
              <div className="flex-col justify-between space-y-4 rounded-[4px] border border-transparent p-4 text-sm">
                <p className="text-gray-700 font-inter">“ECTC’s culture is a mixture of encouragement, ownership, and cutting edge
                  practice. The structure is very horizontal yet focuses on mentorship.”</p>
                <div className="flex space-x-4 text-sm">
                  <div className="border-l pl-4">
                    <h5 className="space-x-2 text-gray-600"><span>Jake</span><span>·</span><span>Design Engineer</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="flex-col justify-between space-y-4 rounded-[4px] border border-transparent p-4 text-sm">
                <p className="text-gray-700 font-inter">“Working at ECTC means working with great people who do excellent work in a
                  culture that is welcoming and highly productive.”</p>
                <div className="flex space-x-4 text-sm">
                  <div className="border-l pl-4">
                    <h5 className="space-x-2 text-gray-600"><span>Arne</span><span>·</span><span>Team Lead</span></h5>
                  </div>
                </div>
              </div>
              <div className="flex-col justify-between space-y-4 rounded-[4px] border border-transparent p-4 text-sm">
                <p className="text-gray-700 font-inter">“ECTC rides the line of being laser focused on developing world class
                  software &amp; user experience while still having a healthy work-life balance.”</p>
                <div className="flex space-x-4 text-sm">
                  <div className="border-l pl-4">
                    <h5 className="space-x-2 text-gray-600"><span>Mikkel</span><span>·</span><span>Principal
                        Engineer</span></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-16 rounded-[4px] pt-8 md:grid-cols-3 md:pt-16 lg:pt-24">
          <div className="flex flex-col space-y-3">
            <div className="flex items-center space-x-2 ">
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h3 className="font-mono text-base text-gray-700 lg:text-xl">Globally remote team</h3>
            </div>
            <p className="text-base leading-loose text-gray-600 font-inter">We believe in providing flexibility in the workplace by
              hiring our team from across the globe.</p>
          </div>
          <div className="flex flex-col space-y-3">
            <div className="flex items-center space-x-2 ">
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h3 className="font-mono text-base text-gray-700 lg:text-xl">Unlimited vacation</h3>
            </div>
            <p className="text-base leading-loose text-gray-600 font-inter">We work hard and we know how important it is to unplug and
              rejuvenate. Take the time you need.</p>
          </div>
          <div className="flex flex-col space-y-3">
            <div className="flex items-center space-x-2 ">
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h3 className="font-mono text-base text-gray-700 lg:text-xl">Home office set up</h3>
            </div>
            <p className="text-base leading-loose text-gray-600 font-inter">We'll make sure you have an awesome set up for Discord,
              Zoom, and everything else!</p>
          </div>
          <div className="flex flex-col space-y-3">
            <div className="flex items-center space-x-2 ">
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h3 className="font-mono text-base text-gray-700 lg:text-xl">Team sprints</h3>
            </div>
            <p className="text-base leading-loose text-gray-600 font-inter">Teamwork makes the dream work. We may be remote, but we
              love coming together as a team to reach our goals and to spend valuable time with one another.</p>
          </div>
          <div className="flex flex-col space-y-3">
            <div className="flex items-center space-x-2 ">
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h3 className="font-mono text-base text-gray-700 lg:text-xl">Parental leave</h3>
            </div>
            <p className="text-base leading-loose text-gray-600 font-inter">We are a family and we know how important that is. We
              offer generous and flexible family leave for everyone.</p>
          </div>
          <div className="flex flex-col space-y-3">
            <div className="flex items-center space-x-2 ">
              <BiSquareRounded className="text-[#00ab44] text-lg"/>
              <h3 className="font-mono text-base text-gray-700 lg:text-xl">Zen fridays</h3>
            </div>
            <p className="text-base leading-loose text-gray-600 font-inter"> We work normal hours but believe in giving everyone an
              extra day of quiet. Friday’s for us mean no Discord, no Slack, and no meetings!</p>
          </div>
        </div>
      </div>


      <div id="jobs" className="flex items-center justify-center w-full px-4 scroll-m-32 bg-gray-02">
        <div className="flex flex-col lg:w-8/12 w-11/12">
          <div className="flex pt-4">
            <div className="grid w-full grid-cols-1 lg:grid-cols-[.2fr_.95fr] justify-between pb-16 lg:pb-32">
              <div className="flex flex-col items-start space-y-4 pb-8">
                {['All positions', 'General', 'Sales'].map((label, index) => (
                  <button
                    key={index}
                    className={`focus-visible:outline-none ${label === selectedLabel ? '!border-gray-300 border-l text-black' : 'text-gray-600'}`}
                    onClick={() => setSelectedLabel(label)}
                    
                  >
                    <div className="white border-l-2 border-transparent px-4 py-1 text-gray-11 hover:bg-gray-03 hover:text-gray-12">
                      <span className="font-mono">{label}</span>
                    </div>
                  </button>
                ))}
              </div>

              {selectedLabel === 'Sales' && 
                <div className="flex h-full min-h-[717px] w-full flex-col items-center justify-start rounded-[4px] border bg-white shadow-xl md:p-8">
                  <div className="mx-8 border-b py-8 last:border-0">
                    <div
                      className="mb-4 flex flex-col items-center justify-between space-y-4 text-left font-mono md:flex-row md:space-y-0">
                      <div className="flex flex-col space-y-2 text-center md:text-left">
                        <h5><span className="text-sm font-medium text-gray-600">Remote</span></h5>
                        <h3 className="flex items-center">
                          <span className="text-2xl font-medium text-gray-12">Solution Architect</span>
                        </h3>
                      </div>
                      <a className="flex h-[40px] items-center justify-center rounded-[4px] px-4 font-mono text-sm border-[1.5px] text-gray-600 border-gray-08 hover:border-gray-09 hover:text-gray-12 space-x-2"
                        href="/about">
                        <span>Apply</span><span>→</span>
                      </a>
                    </div>
                    <div className="prose max-w-none text-gray-600 font-inter prose-p:text-gray-500">
                      <p>
                        Ideal Solution Architect will be at the forefront of driving successful technical engagements with customers by
                        combining deep technical expertise with exceptional communication skills. Will work closely with sales,
                        engineering, and product teams to understand customer requirements, design tailored solutions, and ensure the
                        seamless implementation of complex architectures. Role will involve leading technical demos, conducting POCs,
                        and providing strategic guidance throughout the sales process. Act as a trusted advisor and socialize with
                        prospects and partners to build champions, and help customers navigate technical challenges and achieve their
                        business objectives.
                      </p>
                      <div className="mt-1 font-mono text-sm">USA</div>
                    </div>
                  </div>
                </div>
              }

              {selectedLabel === 'General' && 
                <div className="flex h-full min-h-[717px] w-full flex-col items-center justify-start rounded-[4px] border bg-white shadow-xl md:p-8">
                  <div className="mx-8 border-b py-8 last:border-0">
                    <div className="mb-4 flex flex-col items-center justify-between space-y-4 text-left font-mono md:flex-row md:space-y-0">
                      <div className="flex flex-col space-y-2 text-center md:text-left">
                        <h5><span className="text-sm font-medium text-gray-500">Remote</span></h5>
                        <h3 className="flex items-center "><span className="text-2xl font-medium text-gray-12">Open Application</span></h3>
                      </div><a
                        className="flex h-[40px] items-center justify-center rounded-[4px] px-4 font-mono text-sm  border-[1.5px] text-gray-500 border-gray-08 hover:border-gray-09 hover:text-gray-12 space-x-2"
                        href="/about"><span>Apply</span><span>→</span></a>
                    </div>
                    <div className="prose max-w-none text-gray-500 prose-p:text-gray-500 font-inter">
                      <div className="">We’re&nbsp;always looking for great talent. We welcome you to apply if you're interested in working
                        with us! Share your resume and we'll consider you for future opportunities.<p></p>
                      </div>
                      <div className="mt-1 font-mono text-sm ">USA, United Kingdom</div>
                    </div>
                  </div>
                </div>
              }

              {selectedLabel === 'All positions' && 
                <div className="flex h-full min-h-[717px] w-full flex-col items-center justify-start rounded-[4px] border bg-white shadow-xl md:p-8">
                  <div className="mx-8 border-b py-8 last:border-0">
                    <div className="mb-4  flex flex-col items-center justify-between space-y-4 text-left font-mono md:flex-row md:space-y-0">
                      <div className="flex flex-col space-y-2 text-center md:text-left">
                        <h5><span className="text-sm font-medium text-gray-500">Remote</span></h5>
                        <h3 className="flex items-center "><span className="text-2xl font-medium text-gray-12">Open Application</span></h3>
                      </div><a
                        className="h-[40px] items-center justify-center rounded-[4px] px-4 font-mono text-sm  border-[1.5px] text-gray-500 border-gray-08 hover:border-gray-09 hover:text-gray-12 flex space-x-2"
                        href="/careers/7461671002"><span>Apply</span><span>→</span></a>
                    </div>
                    <div className="prose max-w-none text-gray-500 prose-p:text-gray-500 font-inter">
                      <div>We’re&nbsp;always looking for great talent. We welcome you to apply if you're interested in working with us!
                        Share your resume and we'll consider you for future opportunities.<p></p>
                      </div>
                      <div className="mt-1 font-mono text-sm ">USA, United Kingdom</div>
                    </div>
                  </div>
                  <div className="mx-8 border-b py-8 last:border-0">
                    <div
                      className="mb-4  flex flex-col items-center justify-between space-y-4 text-left font-mono md:flex-row md:space-y-0">
                      <div className="flex flex-col space-y-2 text-center md:text-left">
                        <h5><span className="text-sm font-medium text-gray-500">Remote</span></h5>
                        <h3 className="flex items-center "><span className="text-2xl font-medium text-gray-12">Solution Architect</span></h3>
                      </div><a
                        className="flex h-[40px] items-center justify-center rounded-[4px] px-4 font-mono text-sm  border-[1.5px] text-gray-500 border-gray-08 hover:border-gray-09 hover:text-gray-12 flex space-x-2"
                        href="/careers/7643399002"><span>Apply</span><span>→</span></a>
                    </div>
                    <div className="prose max-w-none text-gray-500 prose-p:text-gray-500 font-inter">
                      <div>Ideal Solution Architect will be at the forefront of driving successful technical engagements with customers
                        by combining deep technical expertise with exceptional communication skills. Will work closely with sales,
                        engineering, and product teams to understand customer requirements, design tailored solutions, and ensure the
                        seamless implementation of complex architectures. Role will involve leading technical demos, conducting POCs,
                        and providing strategic guidance throughout the sales process. Act as a trusted advisor and socialize with
                        prospects and partners to build champions, and help customers navigate technical challenges and achieve their
                        business objectives.<p></p>
                      </div>
                      <div className="mt-1 font-mono text-sm ">USA</div>
                    </div>
                  </div>
                </div>
              }
              
            </div>
          </div>
        </div>
      </div>




    </div>
  )
}

export default About;
