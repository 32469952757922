import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { client } from "../../utils/client";
import { newsDetailMoreQuery } from "../../utils/data";

import { getFormattedDate } from "../../utils/common";

interface NewInfo{
  _id: string;
  title: string;
  subtitle: string;
  duration: string;
  mainImageUrl: string;
  body: Object;
  _createdAt: string;
}


const NotFound = () => {
  const [postDetailMore, setPostDetailMore] = useState<NewInfo[]>([]);

  useEffect(() => {    

    const getMorePostquery = newsDetailMoreQuery('');
  
    client.fetch(getMorePostquery)
    .then((data) => {
      setPostDetailMore(data);
    })
    
  })
  
  return (
    <div className='flex flex-col items-center w-full'>
      <div className='flex flex-col items-center lg:gap-[3rem] gap-10 lg:w-8/12 w-11/12'>
        <div className='flex flex-col w-full items-center px-5 lg:mt-[5rem] mt-10 lg:mb-20'>
          <div className={`leading-relaxed  lg:text-[3.5rem] text-2xl my-3 font-bold text-center`}>
            Oops! It looks like you've wandered off right path
          </div>
          <div className='lg:text-lg text-sm lg:w-3/5 w-full font-inter text-center'>Oops! It seems you've stumbled upon a page that doesn't exist. Don't worry, it happens to the best of us. Here are a few things you can try:</div>

          <div className='font-inter flex flex-col lg:text-lg text-sm lg:w-2/5 w-full gap-2 mt-10 lg:mb-20 mb-10'>
            <div className='flex flex-row gap-3'>
              <span>1.</span>
              <span>Double-check the URL: Ensure that you've entered the correct web address. Sometimes, a simple typo can lead you astray.</span>
            </div>
            <div className='flex flex-row gap-3'>
              <span>2.</span>
              <span>Navigate Back: Use the navigation menu or browser back button to return to the previous page.</span>
            </div>
            <div className='flex flex-row gap-3'>
              <span>3.</span>
              <span>Explore Our Site: Take this opportunity to explore our website. We have a lot to offer, from informative articles and updates to exciting products and services.</span>
            </div>
            <div className='flex flex-row gap-3'>
              <span>4.</span>
              <span>Contact Us: If you're still having trouble finding what you need, feel free to reach out to our support team. We're here to help!</span>
            </div>
          </div>

          <NavLink to={'/'} className='text-lg gap-2 flex flex-row items-center hover:text-black text-gray-500'>
            <i className="fi fi-rr-angle-small-left mb-[-.3rem]"></i>
            Back to homepage
          </NavLink>
        </div>
        
        <div className='flex lg:flex-row flex-col gap-5 justify-between items-start w-full'>
          <div className='flex flex-col gap-3 lg:text-lg text-sm'>
            <div className='lg:text-[2.5rem] text-center text-2xl'>
            Latest news & updates
            </div>
            <div className='grid lg:grid-cols-3 lg:gap-[4rem] gap-5 lg:mt-10 mt-5'>
              {postDetailMore?.map((post, index) => (
                <div className='rounded-[4px] text-left h-full' key={post._id}>
                  <NavLink to={`/updates/${post?._id}`} className="rounded-[4px] border">
                    <img src={post?.mainImageUrl} alt={`news-${index}`} />
                  </NavLink>
                  <div className='mb-2 mt-4 text-sm text-gray-500'>
                    {getFormattedDate(post?._createdAt)}
                  </div>
                  <div className='text-xl font-medium text-gray-800 group-hover:underline'>
                    {post?.title}
                  </div>
    
                  <NavLink to={`/updates/${post?._id}`} className=' mt-4 flex items-center gap-2  text-gray-500 text-sm'>
                    Read more
                    <i className="fi fi-rr-arrow-small-right mb-[-.3rem]"></i>
                  </NavLink>
                </div>
              ))}
            </div>
            <div className={`${postDetailMore.length !== 0 ? 'hidden' : ''} w-full flex flex-col gap-3 items-center justify-center my-[5rem]`}>
              <div className='text-2xl  font-semibold mb-3'>No news found</div>
            </div>
            <div className='flex flex-col items-center gap-8 my-10'>
              <NavLink to={`/updates`} className='mt-4 flex items-center gap-2 hover:text-black text-gray-500 text-lg'>
                View all news
                <i className="fi fi-rr-arrow-small-right mb-[-.3rem]"></i>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound;
